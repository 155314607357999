import React, { useEffect, useState } from "react";

import { Mobile, NotMobile } from "./responsive";

import http from "../../services/httpService";

import { ReactComponent as CalendarIcon } from "../../assets/calendar-icon.svg";
import { ReactComponent as PlayIcon } from "../../assets/play-icon.svg";

import "../../styles/components/common/seminar-scheduler.scss";

function SeminarScheduler() {
  const [yumaMainRemainingSpots, setYumaMainRemainingSpots] = useState(1);
  const [foothillsRemainingSpots, setFoothillsRemainingSpots] = useState(1);
  //const [heritageRemainingSpots, setHeritageRemainingSpots] = useState(1);

  useEffect(() => {
    const getRemainingSpots = async () => {
      const responseYuma = await http.get("/getRemainingSpotsYuma");
      setYumaMainRemainingSpots(responseYuma.data.result);

      const responseFoothills = await http.get("/getRemainingSpotsFoothills");
      setFoothillsRemainingSpots(responseFoothills.data.result);

      /*const responseHeritage = await http.get("/getRemainingSpotsHeritage");
      setHeritageRemainingSpots(responseHeritage.data.result);*/
    };
    getRemainingSpots();
  }, []);

  const event = (date, time, location, appointmentTypeId, remainingSpots) => {
    return (
      <div className="event-info">
        <span className="fw-bolder font-24 mb-3">
          Free Estate Planning Seminar
        </span>
        <span className="font-18 mb-2">
          {date} @ {time}
        </span>
        <span className="font-16">{location}</span>
        <button
          className="mt-4 btn-filled-tertiary font-18 p-4"
          onClick={() =>
            window.open(
              `https://dghbooknow.as.me/schedule.php?appointmentType=${appointmentTypeId}`
            )
          }
          disabled={remainingSpots <= 0}
        >
          <CalendarIcon />
          Reserve Now
        </button>
        {remainingSpots <= 0 && (
          <span className="align-self-center mt-3 error font-16">
            Sorry, this seminar is full
          </span>
        )}
      </div>
    );
  };

  const yumaMainEvent = event(
    "April 25th, 2025",
    "10:30am",
    "Yuma Main Library",
    "38594061",
    yumaMainRemainingSpots
  );

  const foothillsEvent = event(
    "April 25th, 2025",
    "2:30pm",
    "Foothills Library",
    "38594172",
    foothillsRemainingSpots
  );

  /*const heritageEvent = event(
    "August 22nd, 2023",
    "10:30am",
    "Heritage Library",
    "51250076",
    heritageRemainingSpots
  );*/

  return (
    <div className="seminar-scheduler">
      <h1 className="my-1 font-48">Estate Planning Seminars</h1>
      <h2 className="mt-2 mb-5 font-24">
        Join us live and get your questions answered
      </h2>

      {/*<p className="white">
        There are currently no live seminars scheduled. Check back soon for new
        dates!
  </p>*/}

      <Mobile>
        {foothillsEvent}
        {<div className="mt-4">{yumaMainEvent}</div>}
      </Mobile>
      <NotMobile>
        <div className="d-flex mt-5">
          {foothillsEvent}
          {<div className="ms-5">{yumaMainEvent}</div>}
        </div>
      </NotMobile>

      <h2 className="mt-5 font-24">
        Unable to join us in person? Watch a recorded seminar online
      </h2>
      <button
        className="mt-4 btn-filled-tertiary font-18 p-4"
        onClick={() =>
          window.open(
            "https://dghlaw.clickfunnels.com/auto-webinar-registration1588286121274"
          )
        }
      >
        <PlayIcon />
        Watch Now
      </button>
    </div>
  );
}

export default SeminarScheduler;
